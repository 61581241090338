.search_section {
    padding: 2rem 0;
    background: white;
    position: relative;
}
.search_section form {
    background: #f5f5f5;
    padding: 0.5rem;
    border-radius: 0.5rem;
    display: inline-block;
    width: 100%;
    position: relative;
}
.search_section form .heading {
    display: inline-block;
    width: 100%;
    position: relative;
    color: var(--color-primary);
    font-weight: 600;
    font-size: 1.25rem;
}
.search_section form .heading .close {
    width: 1.75rem;
}
.search_section form .white_box {
    display: inline-block;
    width: 100%;
    position: relative;
    background: white;
    border-radius: 0.35rem;
    border: 0.1rem solid var(--color-primary);
    padding: 0.5rem 0.75rem;
    margin-top: 1rem;
}

.related-item {
    padding: 8px 0px;
    word-wrap: break-word;
    overflow: hidden;
    font-weight: 600;
}
.search_section form .white_box .head {
    display: inline-block;
    width: 100%;
    position: relative;
    color: var(--color-primary);
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
}
.search_section form .white_box .slider_value {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 0.25rem;
}
.search_section form .white_box .slider_value span {
    color: #e5e5e5;
    font-weight: 500;
    font-size: 1rem;
}
.search_section form .white_box .slider_value span.max {
    float: right;
}
.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 0.25rem;
    border-radius: 1rem;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.4s;
    transition: opacity 0.4s;
}
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: var(--color-primary);
    cursor: pointer;
}
.slider::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: var(--color-primary);
    cursor: pointer;
}
.search_section form .btn_red {
    border: 0;
    background: #f15c69;
    color: white;
    border-radius: 0.45rem;
    font-size: 1.1rem;
    padding: 0.35rem 2.5rem;
    border: 0.1rem solid #f15c69;
    margin-top: 0.5rem;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}
.search_section form .btn_red:hover,
.search_section form .btn_red:focus {
    background: white;
    color: #f15c69;
}
.search_section form .reset {
    background: transparent;
    border: 0;
    color: var(--color-primary);
    font-weight: 600;
    font-size: 1.2rem;
    display: inline-block;
    margin-left: 1.5rem;
    cursor: pointer;
}
.search_section form .white_box:last-child {
    margin-top: 0rem;
}
.search_section form .white_box ul li a {
    display: inline-block;
    width: 100%;
    position: relative;
    color: #666666;
    font-size: 0.9rem;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}
.search_section form .white_box ul li a:hover,
.search_section form .white_box ul li a:focus {
    color: var(--color-primary);
}
.order {
    order: 2;
}
@media (max-width: 767px) {
    .order {
        order: initial;
    }
}

.more {
    text-align: center;
    font-size: 140%;
    margin: 20px auto;
    color: var(--color-primary);
    font-weight: 800;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    padding: 10px 0;
    background-color: #fff;
}
