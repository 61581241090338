.products {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 1rem;
}

@media (max-width: 1024px) {
    .products {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 768px) {
    .products {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 576px) {
    .products {
        grid-template-columns: 1fr;
    }
}
