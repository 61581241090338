.content_section .trend_search {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
}
.content_section .trend_search a img {
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}

.m_row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}
.m_col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.main_categories {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-top: 0.75rem;
}

.search_results {
    position: relative;
    width: 100%;
    display: inline-block;
    margin-bottom: 2rem;
}

.update_section {
    padding: 14rem 0;
    padding-bottom: 4rem;
    display: flex;
    width: 100%;
    position: relative;
    background-image: url(./images/update_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.update_section.no-img {
    background-image: none;
    background-color: var(--color-primary);
}

.update_section .text {
    color: white;
    font-size: 2.25rem;
    line-height: 1.2;
}
.update_section .text a {
    color: white;
    font-weight: 600;
    font-size: 2.5rem;
}
@media (max-width: 767px) {
    .update_section {
        background-image: url(./images/update_mob.png);
        padding-top: 5rem;
        padding-bottom: 20rem;
    }
}
@media (max-width: 576px) {
    .update_section {
        padding-top: 2rem;
        padding-bottom: 10rem;
    }
    .update_section .text {
        font-size: 1.25rem;
    }
    .update_section .text a {
        font-size: 1.5rem;
    }
}

.more {
    text-align: center;
    font-size: 140%;
    margin: 20px auto;
    color: var(--color-primary);
    font-weight: 800;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    padding: 10px 0;
    background-color: #fff;
}
