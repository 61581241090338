.header .header-bg {
    position: relative;
    padding-top: 3.5rem;
    padding-bottom: 5.5rem;
    background-image: url(./images/desktop_masthead.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-color: #f5f5f5;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.header .header-bg.no-img {
    background-image: none;
    background-color: var(--color-primary);
}

.header {
    background-color: #f5f5f5;
    overflow: hidden;
}

.header .logo {
    width: 10.5rem;
}

.header h1 {
    color: white;
    font-size: 4.5rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 2rem;
    margin-top: -1.5rem;
}

.header form {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 0.75rem;
}
.header form input {
    background: #f8f8f8;
    border-radius: 3rem;
    border: 0;
    height: 3rem;
    padding: 0.75rem 2rem;
    color: black;
    padding-top: 0.95rem;
}
.header form input::placeholder {
    font-size: 1.1rem;
    color: #e5e5e5;
    font-weight: 500;
}
.header form .btn_submit {
    background: #f15c69;
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 3rem;
    font-size: 1.3rem;
    color: white;
    padding: 0.55rem 2rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}
.header form .btn_submit:hover,
.header form .btn_submit:focus {
    background: #bd3540;
}
.header p {
    font-size: 1.4rem;
    color: white;
}
.header2 {
    background: url(./images/nav_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0;
    padding: 2rem 0;
}
.header2 form {
    margin-bottom: 0;
    margin-top: 0.75rem;
}

@media (max-width: 767px) {
    .header .header-bg {
        background-image: url(./images/mobile_masthead.jpg);
        padding-bottom: 22.5rem;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
        background-position-x: 100%;
        background-position-y: 100%;
        background-size: cover;
        z-index: 9;
    }
    .header h1 {
        margin-top: 2rem;
    }
    .header2 {
        padding-bottom: 2rem;
    }
}
@media (max-width: 576px) {
    .header {
        padding-bottom: 10.5rem;
    }
    .header h1 {
        font-size: 3.5rem;
    }
    .header2 {
        padding-bottom: 2rem;
    }
}
