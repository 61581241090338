.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.contact-form label {
    margin: 1rem 0;
}

.contact-form button {
    margin: 2rem 0;
}

.contact-form .error-message {
    color: red;
}
