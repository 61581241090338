.category_detail {
    display: inline-block;
    width: 100%;
    position: relative;
}
.category_detail .slick-slide {
    padding: 0 1.25rem;
}
.category_detail .slick-list {
    padding-top: 2rem !important;
}

.category_slider {
    overflow: scroll;
}

.main_categories {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-top: 0.75rem;
}
.slider_section {
    position: relative;
    padding: 2rem 0;
    background: white;
}
.slider_section .center .slick-arrow {
    display: none !important;
}
.slider_section .slick-slide {
    padding: 0 0.5rem;
}
.slider_section .center .category_img {
    width: 100%;
    min-height: 14.25rem;
}
.slider_section .slick-slide:focus {
    outline: 0 !important;
    border: 0 !important;
}
.slider_section .slick-active {
    outline: 0 !important;
    border: 0 !important;
}
.slider_section .slick-slide a:focus {
    outline: 0 !important;
    border: 0 !important;
}
.slider_section .slick-active a {
    outline: 0 !important;
    border: 0 !important;
}
.slider_section .slick-slide div:focus {
    outline: 0 !important;
    border: 0 !important;
}
.slider_section .slick-active div {
    outline: 0 !important;
    border: 0 !important;
}
.top_products {
    display: inline-block;
    width: 100%;
    position: relative;
}
.top_products .btn_more {
    color: var(--color-primary);
    font-weight: 600;
    font-size: 1.25rem;
    padding: 0.5rem 3.5rem;
    display: inline-block;
    position: relative;
    border: 0.1rem solid var(--color-primary);
    background: white;
    border-radius: 3rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}
.top_products .btn_more:hover,
.top_products .btn_more:focus {
    background: var(--color-primary);
    color: white;
}
.search_results {
    position: relative;
    width: 100%;
    display: inline-block;
    margin-bottom: 2rem;
}
.read_more .center3 .slick-slide {
    padding: 0 1rem;
}
.read_more {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.slide {
    padding: 0 1rem;
}

@media (max-width: 1440px) {
    .main_categories .center .category_box .hover_box {
        top: 50%;
    }
    .slider_section .center .category_img {
        width: 100%;
        min-height: 12.25rem;
    }
}
@media (max-width: 1200px) {
    .slider_section .center .category_img {
        min-height: 11.25rem;
    }
}
@media (max-width: 768px) {
    .slider_section .center .category_img {
        min-height: 12.25rem;
    }
}

.more {
    text-align: center;
    font-size: 140%;
    margin: 20px auto;
    color: var(--color-primary);
    font-weight: 800;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    padding: 10px 0;
    background-color: #fff;
}
