.category_box {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
}
.category_box .hover_box {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0.75rem;
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 0;
    right: 0;
    border-radius: 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}
.center .category_box .hover_box {
    top: 60%;
}
.category_box .hover_box .text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    padding-right: 5rem;
    line-height: 1.2;
    min-height: 3.5rem;
}
.category_box .hover_box .text .category_icon {
    position: absolute;
    right: 0;
    top: -0.35rem;
    width: 4.5rem;
}
.category_box:hover .hover_box {
    border-radius: 0.5rem;
    top: 0rem;
}

.category_box .hover_box .text .category_icon img {
    width: 90%;
    display: block;
    margin: auto;
}
.category_img {
    min-height: 11rem;
    border-radius: 0.5rem;
}

@media (max-width: 576px) {
    .category_box .hover_box {
        top: 4.5rem;
    }
    .center .category_box .hover_box {
        top: 70%;
    }
}
