.how_section {
    position: relative;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 3.5rem 0;
}
.how_section .container {
    position: relative;
}
.how_section .head {
    font-size: 2rem;
    font-weight: 600;
    color: white;
    margin-bottom: 0.5rem;
}
.how_section p {
    color: white;
    font-weight: normal;
    font-size: 1rem;
    margin-bottom: 3rem;
}
.how_section .number {
    position: absolute;
    right: -8rem;
    bottom: -12rem;
    color: white;
    width: 30rem;
    font-weight: 800;
    font-size: 20rem;
    text-align: right;
}
@media (max-width: 1440px) {
    .how_section .number {
        right: 0;
    }
}
@media (max-width: 767px) {
    .how_section p {
        margin-bottom: 0rem;
    }
    .how_section .number {
        position: relative;
        display: block;
        margin-left: auto;
        bottom: -9rem;
    }
}
