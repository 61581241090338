.product_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}
.product_box .top {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    position: relative;
    border: 0.1rem solid var(--color-primary);
    padding: 0.5rem 0.75rem;
    background: white;
    border-radius: 0.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.product_box:hover .top,
.product_box:focus .top {
    background: #eafff8;
}
.product_box .price {
    color: #dddddd;
    font-weight: 600;
    font-size: 1.1rem;
    display: inline-block;
    width: 100%;
    position: relative;
}
.product_box .price span {
    float: right;
}
.product_box .product_img {
    width: 100%;
    margin: 0 0 1rem 0;
}

.product-box .product_img:before {
    background: transparent;
    content: '';
}
.product_box .name {
    color: #666666;
    font-weight: 600;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.4;
    word-break: break-all;
    overflow: hidden;
}
.product_box .name span {
    font-weight: 400;
    font-size: 0.9rem;
}
.product_box .sale {
    background: #f15c69;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.1rem;
    text-align: center;
    border-radius: 50%;
    font-weight: 500;
    position: absolute;
    right: 0.5rem;
    bottom: 3.5rem;
}
.product_box .btn_price {
    color: var(--color-primary);
    font-weight: 600;
    font-size: 1rem;
    padding: 1rem;
    height: 3rem;
    display: inline-block;
    width: 100%;
    position: relative;
    border: 0.1rem solid var(--color-primary);
    border-top: 0;
    background: white;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}
.product_box:hover .btn_price,
.product_box:focus .btn_price {
    background: var(--color-primary);
    color: white;
}
.product_box .btn_price img {
    position: absolute;
    right: 1rem;
    top: 0.75rem;
    width: 1.75rem;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}
.product_box .btn_price .green {
    visibility: visible;
    opacity: 1;
}
.product_box .btn_price .white {
    visibility: hidden;
    opacity: 0;
    width: 1rem;
    top: 0.85rem;
}
.product_box:hover .btn_price .green,
.product_box:focus .btn_price .green {
    visibility: hidden;
    opacity: 0;
}
.product_box:hover .btn_price .white,
.product_box:focus .btn_price .white {
    visibility: visible;
    opacity: 1;
}
@media (max-width: 767px) {
    .product_box .btn_price {
        font-size: 1.5rem;
        padding: 0.65rem 1rem;
    }
}
