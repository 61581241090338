.store-link img {
    max-width: 100%;
    transition: all ease 0.4s;
    border-radius: 100%;
}
.store-link img:hover,
.store-link img:focus {
    transform: scale(1.1);
}

.store-link a:hover,
.store-link a:focus {
    text-decoration: none;
}
