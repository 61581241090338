@font-face {
    src: url(assets/fonts/samsungsharpsans.otf);
    font-family: 'samsungsharpsans';
    font-weight: normal;
    font-style: normal;
}
@font-face {
    src: url(assets/fonts/samsungsharpsans-medium.otf);
    font-family: 'samsungsharpsans';
    font-weight: 500;
    font-style: normal;
}
@font-face {
    src: url(assets/fonts/samsungsharpsans-bold.otf);
    font-family: 'samsungsharpsans';
    font-weight: 600;
    font-style: normal;
}
body,
html {
    margin: 0;
    font-size: 16px;
    font-family: 'samsungsharpsans';
}
ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}
input:focus,
button:focus,
textarea:focus {
    outline: none;
}
a:hover,
a:focus {
    text-decoration: none;
}
img {
    max-width: 100%;
}
.slick-arrow {
    border: 0;
    background: transparent;
    color: transparent;
    width: 0;
    position: absolute;
    top: 45%;
    z-index: 99;
}
.slick-arrow.slick-prev {
    left: 1rem;
}
.slick-arrow.slick-next {
    right: 3rem;
}
.slick-arrow.slick-prev:after {
    content: '';
    background: url(assets/images/slick_left.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
    display: inline-block;
}
.slick-arrow.slick-next:after {
    content: '';
    background: url(assets/images/slick_right.svg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 2rem;
    height: 2rem;
    display: inline-block;
}
.content_section {
    position: relative;
    background: #f5f5f5;
    padding: 1rem 0;
}
.content_section .heading {
    color: var(--color-primary);
    font-size: 1.95rem;
    font-weight: 600;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.content_section2 {
    background: white;
    padding-top: 4rem;
    padding-bottom: 1rem;
}
footer {
    background: #000000;
    padding: 0.5rem 0;
}
footer p {
    margin-bottom: 0;
    color: var(--color-primary);
    font-size: 1.1rem;
}
.selectpicker {
    padding: 4.5px 9px;
    border-radius: 3px;
    background: url(./assets/images/arrow_down.svg), #ffffff;
    width: 100% !important;
    margin-top: 0.75rem;
    border: 0.1rem solid var(--color-primary);
    color: var(--color-primary);
    font-weight: 600;
    font-size: 1.25rem;
    background-repeat: no-repeat;
    background-size: 1.5rem;
    background-position: 95% 30%;
    outline: 0 !important;
    box-shadow: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.selectpicker::-ms-expand {
    display: none;
}
.selectpicker option {
    font-weight: normal;
}

.label {
    padding-left: 35px;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--color-primary);
    display: inline-block;
    width: 100%;
    position: relative;
    background: white;
    border-radius: 0.35rem;
    border: 0.1rem solid var(--color-primary);
    padding: 0.5rem 0.75rem;
    margin-top: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0.65rem;
    right: 0.5rem;
    left: initial;
    height: 1.5rem;
    width: 1.5rem;
    background-color: white;
    border: 0.1rem solid var(--color-primary);
    border-radius: 0.25rem;
}
.label:hover input ~ .checkmark {
    background-color: var(--color-primary);
}
.label input:checked ~ .checkmark {
    background-color: var(--color-primary);
}
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}
.label input:checked ~ .checkmark:after {
    display: block;
}
.label .checkmark:after {
    left: 0.5rem;
    top: 0.2rem;
    width: 0.35rem;
    height: 0.75rem;
    border: solid white;
    border-width: 0 0.15rem 0.15rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.visible-xs {
    display: none !important;
}
.hidden-xs {
    display: block !important;
}
@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
    .hidden-xs {
        display: none !important;
    }
}
@media (max-width: 1440px) {
    body,
    html {
        font-size: 14px;
    }
}
@media (max-width: 1366px) {
    body,
    html {
        font-size: 13px;
    }
}
@media (max-width: 1200px) {
    body,
    html {
        font-size: 12px;
    }
}
@media (max-width: 1024px) {
    body,
    html {
        font-size: 10px;
    }
}
@media (max-width: 768px) {
    body,
    html {
        font-size: 8px;
    }
}
@media (max-width: 767px) {
    body,
    html {
        font-size: 12px;
    }
    .content_section {
        margin-top: -3rem;
        padding-top: 3rem;
    }
}
@media (max-width: 576px) {
    body,
    html {
        font-size: 11px;
    }
}
