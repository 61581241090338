.basement {
    background: #494949;
    padding-top: 3rem;
    padding-bottom: 8rem;
    position: relative;
}
.basement h4 {
    color: var(--color-primary);
    font-size: 1.35rem;
    margin-top: 2rem;
    margin-bottom: 0.75rem;
    font-weight: 500;
}
.basement ul li a {
    color: white;
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    transition: all ease 0.4s;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}
.basement ul li a:hover,
.basement ul li a:focus {
    color: var(--color-primary);
}
.basement .card {
    border: 0;
    padding: 0;
    border-radius: 0;
    background: transparent;
}
.basement .card .card-header {
    background: transparent;
    border: 0;
    padding: 0;
}
.basement .card .card-header a {
    color: var(--color-primary);
    padding: 0.25rem 0rem;
    font-size: 1.25rem;
    font-weight: 500;
    border-bottom: 0.15rem solid #666666;
    width: 100%;
    display: inline-block;
    position: relative;
}
.basement .card .card-body {
    padding: 0.5rem 0;
}
.basement .card .card-header img {
    width: 1.5rem;
    float: right;
}
.basement .card .card-header a .right {
    display: block;
}
.basement .card .card-header a .down {
    display: none;
}
.basement .card .card-header a.active .right {
    display: none;
}
.basement .card .card-header a.active .down {
    display: block;
}
